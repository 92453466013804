import React, { createContext, useContext, useState, useEffect } from 'react';

const UrlParametersContext = createContext();

export const UrlParametersProvider = ({ children }) => {
  const [urlParameters, setUrlParameters] = useState({});
  useEffect(() => {
    // Parse the URL parameters when the component mounts
    const params = new URLSearchParams(window.location.search);
    const parameters = {};
    
    for (const [key, value] of params.entries()) {
      parameters[key] = value;
    }
    
    setUrlParameters(parameters);
  }, []);
  return (
    <UrlParametersContext.Provider value={urlParameters}>
      {children}
    </UrlParametersContext.Provider>
  );
};

export const useUrlParameters = () => useContext(UrlParametersContext);