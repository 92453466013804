/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import { AnimatePresence } from "framer-motion";
import { UrlParametersProvider } from "./src/context/urlParametersContext";
import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://9d2c3baecfd079371fa82df5611017e3@o4507449351536640.ingest.us.sentry.io/4507453485678592",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost:8000", "https://www.pureweb.com"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <UrlParametersProvider>
        <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
      </UrlParametersProvider>
    </>
  );
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const transitionDelay = 600;
  window.history.scrollRestoration = "manual";
  const currentPosition = getSavedScrollPosition(location);
  window.setTimeout(() => {
    window.scrollTo(...currentPosition);
  }, transitionDelay);
  return false;
};

// Partytown
export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== "production") {
    return null;
  }

  // const pagePath = location
  //   ? location.pathname + location.search + location.hash
  //   : undefined;
  // setTimeout(() => {
  //   if (typeof gtag === "function" && gtag !== undefined) {
  //     gtag("event", "page_view", { page_path: pagePath });
  //   }
  // }, 300);
};
